





































































































































































































































































import { GraduationController, StudentController } from '@/services/request.service'
import { Component, Vue } from 'vue-property-decorator'
import { round } from 'lodash'

@Component
export default class ExportAcademicProgressReport extends Vue {
  private loaded = false
  private student: any = {
    lastName: '',
    enName: '',
    firstName: '',
    name: '',
    sectionName: '',
    house: '',
    className: '',
    studentNum: '',
  }
  private progress: any = {
    graduationStandard: '',
    compulsory: 0,
    compulsoryTotal: 0,
    elective: 0,
    electiveTotal: 0,
    graduationSchedule: 0,
  }
  private schoolCourseCredits: Array<any> = []
  private outOfSchoolCourseCredits: Array<any> = []
  private schedules: Array<any> = []

  private round = round

  private get studentId(): number {
    return parseInt(this.$route.params.studentId, 10)
  }

  private get schoolCourseCreditsColumns(): Array<any> {
    return [
      {
        dataIndex: 'courseName',
        slots: { title: 'course-title' },
        width: '15%',
      },
      {
        dataIndex: 'subjectName',
        slots: { title: 'subject-title' },
        width: '12%',
      },
      {
        dataIndex: 'courseType',
        slots: { title: 'course-type-title' },
        width: '8%',
      },
      {
        dataIndex: 'schoolYearName',
        slots: { title: 'school-year-title' },
        width: '8%',
      },
      {
        dataIndex: 'status',
        slots: { title: 'status-title' },
        width: '10%',
        scopedSlots: { customRender: 'status' },
      },
      {
        dataIndex: 'teachers',
        slots: { title: 'teacher-title' },
        width: '15%',
      },
      {
        dataIndex: 'finalScore',
        slots: { title: 'final-title' },
        width: '10%',
        scopedSlots: { customRender: 'finalScore' },
      },
      {
        dataIndex: 'level',
        slots: { title: 'grade-title' },
        width: '5%',
      },
      {
        dataIndex: 'credit',
        slots: { title: 'credit-title' },
        width: '5%',
      },
      {
        dataIndex: 'scoringType',
        slots: { title: 'calculated-type-title' },
        width: '12%',
        scopedSlots: { customRender: 'scoringType' },
      },
    ].map(item => {
      return {
        ...item,
        customCell: record => {
          return {
            style: { padding: '6px 4px', backgroundColor: '#fff' },
          }
        },
        customHeaderCell: record => {
          return {
            style: { padding: '6px 4px' },
          }
        },
      }
    })
  }

  private get outOfSchoolCourseCreditsColumns(): Array<any> {
    return [
      {
        dataIndex: 'courseName',
        slots: { title: 'course-title' },
        width: '15%',
      },
      {
        dataIndex: 'subjectName',
        slots: { title: 'subject-title' },
        width: '15%',
      },
      {
        dataIndex: 'schoolYearName',
        slots: { title: 'school-year-title' },
        width: '10%',
      },
      {
        dataIndex: 'relevancyCourseName',
        slots: { title: 'associate-title' },
        width: '20%',
      },
      {
        dataIndex: 'grade',
        slots: { title: 'score-title' },
        width: '5%',
      },
      {
        dataIndex: 'credit',
        slots: { title: 'credit-title' },
        width: '5%',
      },
      {
        dataIndex: 'scoringType',
        slots: { title: 'calculated-type-title' },
        width: '15%',
        scopedSlots: { customRender: 'scoringType' },
      },
      {
        dataIndex: 'remark',
        slots: { title: 'remark-title' },
        width: '15%',
      },
    ].map(item => {
      return {
        ...item,
        customCell: record => {
          return {
            style: { padding: '6px 4px', backgroundColor: '#fff' },
          }
        },
        customHeaderCell: record => {
          return {
            style: { padding: '6px 4px' },
          }
        },
      }
    })
  }

  private get courseColumns(): Array<any> {
    return [
      {
        dataIndex: 'courseName',
        slots: { title: 'course-title' },
        width: '15%',
      },
      {
        dataIndex: 'subjectName',
        slots: { title: 'subject-title' },
        width: '15%',
      },
      {
        dataIndex: 'courseCredits',
        slots: { title: 'credit-title' },
        width: '5%',
      },
      {
        dataIndex: 'remark',
        slots: { title: 'remark-title' },
        width: '15%',
      },
    ].map(item => {
      return {
        ...item,
        customCell: record => {
          return {
            style: { padding: '6px 4px', backgroundColor: '#fff' },
          }
        },
        customHeaderCell: record => {
          return {
            style: { padding: '6px 4px' },
          }
        },
      }
    })
  }

  private created(): void {
    this.getData()
  }

  private getData(): void {
    const { studentId } = this
    Promise.all([
      StudentController.getStudentBriefInfo(studentId),
      GraduationController.studentStandard(studentId),
      GraduationController.getStudentAcademicInfo(studentId),
      GraduationController.graduationSchedule(studentId),
    ])
      .then(res => {
        this.student = res[0].data
        this.progress = res[1].data
        this.schoolCourseCredits = res[2].data.courseInfoResponses.map(item => {
          return {
            ...item,
            teachers: item.teachers.map(teacher => teacher.name).join(', '),
          }
        })
        this.outOfSchoolCourseCredits = res[2].data.extraCourseResponses
        this.schedules = res[3].data.scsResponses
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loaded = true
      })
  }
}
